var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "ValidatedFormCard",
        {
          attrs: {
            titleTranslationKey: "components.profile.CustomerProfile.title",
            ready: _vm.ready,
            model: _vm.$v,
            onSave: _vm.save,
            showDiscardButton: true
          },
          on: { discardChanges: _vm.onDiscardChanges }
        },
        [
          _c(
            "CRow",
            [
              _c(
                "CCol",
                { attrs: { md: "6" } },
                [
                  _c(
                    "ValidatedInput",
                    _vm._g(
                      {
                        attrs: {
                          translationKey: "customerProfile.displayName",
                          field: _vm.$v.customerProfile.displayName,
                          value: _vm.$v.customerProfile.displayName.$model
                        },
                        on: {
                          "update:value": function($event) {
                            return _vm.$set(
                              _vm.$v.customerProfile.displayName,
                              "$model",
                              $event
                            )
                          }
                        }
                      },
                      _vm.$listeners
                    )
                  )
                ],
                1
              ),
              _c("CCol", { attrs: { md: "6" } }),
              _c(
                "CCol",
                { attrs: { md: "6" } },
                [
                  _c(
                    "ValidatedInput",
                    _vm._g(
                      {
                        attrs: {
                          translationKey: "customerProfile.firstName",
                          field: _vm.$v.customerProfile.firstName,
                          value: _vm.$v.customerProfile.firstName.$model
                        },
                        on: {
                          "update:value": function($event) {
                            return _vm.$set(
                              _vm.$v.customerProfile.firstName,
                              "$model",
                              $event
                            )
                          }
                        }
                      },
                      _vm.$listeners
                    )
                  )
                ],
                1
              ),
              _c(
                "CCol",
                { attrs: { md: "6" } },
                [
                  _c(
                    "ValidatedInput",
                    _vm._g(
                      {
                        attrs: {
                          translationKey: "customerProfile.lastName",
                          field: _vm.$v.customerProfile.lastName,
                          value: _vm.$v.customerProfile.lastName.$model
                        },
                        on: {
                          "update:value": function($event) {
                            return _vm.$set(
                              _vm.$v.customerProfile.lastName,
                              "$model",
                              $event
                            )
                          }
                        }
                      },
                      _vm.$listeners
                    )
                  )
                ],
                1
              ),
              _c(
                "CCol",
                { attrs: { md: "6" } },
                [
                  _c(
                    "ValidatedInput",
                    _vm._g(
                      {
                        attrs: {
                          translationKey: "customerProfile.phoneNumber",
                          field: _vm.$v.customerProfile.phoneNumber,
                          value: _vm.$v.customerProfile.phoneNumber.$model
                        },
                        on: {
                          "update:value": function($event) {
                            return _vm.$set(
                              _vm.$v.customerProfile.phoneNumber,
                              "$model",
                              $event
                            )
                          }
                        }
                      },
                      _vm.$listeners
                    )
                  )
                ],
                1
              ),
              _c(
                "CCol",
                { attrs: { md: "12" } },
                [
                  _c(
                    "AddressForm",
                    _vm._g(
                      {
                        attrs: {
                          field: _vm.$v.customerProfile,
                          value: _vm.$v.customerProfile.$model
                        },
                        on: {
                          "update:value": function($event) {
                            return _vm.$set(
                              _vm.$v.customerProfile,
                              "$model",
                              $event
                            )
                          }
                        }
                      },
                      _vm.$listeners
                    )
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("Notification", {
        attrs: {
          icon: "cilCheck",
          text: _vm.$t(
            "components.profile.CustomerProfile.notificationMsg.success"
          ),
          show: _vm.showSuccessNotification
        },
        on: {
          onClose: function($event) {
            _vm.showSuccessNotification = false
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }